import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './mockFirebaseConfig';
import './LandingPage.css';

const LandingPage = () => {
  const [user, setUser] = useState(null);
  const [courses, setCourses] = useState([]);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState('');
  const transitionTimeoutRef = useRef(null);
  const navigate = useNavigate();
  
  const TRANSITION_DURATION = 3000; 
  const IMAGE_DISPLAY_DURATION = 5000; 

  const preloadImages = (images) => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setCourses([
          { id: 1, name: 'MECH3202: Fluid Dynamics' },
          // { id: 2, name: 'MECH2202: Thermodynamics', underConstruction: true },
          { id: 2, name: 'MECH2202: Thermodynamics'},
          { id: 3, name: 'ARIA: Reviewer-Planner'},
        ]);
      }
    });

   
    const importAll = (r) => r.keys().map(r);
    const images = importAll(require.context('../public/images', false, /\.(png|jpe?g|svg)$/));
    setBackgroundImages(images);
    preloadImages(images);

    return () => unsubscribe();
  }, []);

  const cycleImage = useCallback(() => {
    const currentElement = document.querySelector('.cycling-background.current');
    const nextElement = document.querySelector('.cycling-background.next');
    
    if (currentElement && nextElement) {
      currentElement.classList.remove('current');
      currentElement.classList.add('fade-out');
      nextElement.classList.remove('next');
      nextElement.classList.add('current');
    }
    
    if (transitionTimeoutRef.current) clearTimeout(transitionTimeoutRef.current);
    
    transitionTimeoutRef.current = setTimeout(() => {
      setCurrentImageIndex(nextImageIndex);
      setNextImageIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, TRANSITION_DURATION);
  }, [backgroundImages.length, nextImageIndex]);
  // aria-reviewer-planner.profanswers.com
  useEffect(() => {
    if (backgroundImages.length > 1) {
      const intervalId = setInterval(() => {
        cycleImage();
      }, IMAGE_DISPLAY_DURATION);
  
      return () => {
        clearInterval(intervalId);
        if (transitionTimeoutRef.current) clearTimeout(transitionTimeoutRef.current);
      };
    }
  }, [cycleImage, IMAGE_DISPLAY_DURATION, backgroundImages.length]);

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleCourseSubmit = (event) => {
    event.preventDefault();
    console.log('Selected course ID:', selectedCourse);    
    if (selectedCourse) {
      const selectedCourseDetails = courses.find(course => course.id === parseInt(selectedCourse));
      console.log('Selected course details:', selectedCourseDetails);
      
      if (selectedCourseDetails.name === 'MECH3202: Fluid Dynamics') {
        window.location.href = 'https://mech3202.profanswers.com/login';
      } else if (selectedCourseDetails.name === 'MECH2202: Thermodynamics' && !selectedCourseDetails.underConstruction) {
        window.location.href = 'https://mech2201.profanswers.com/login';
      } else if (selectedCourseDetails.name === 'ARIA: Reviewer-Planner') {
        window.location.href = 'https://aria-reviewer-planner.profanswers.com/login';
      }
      
    }
  };
  return (
    <div className="page-container">
      <div 
        className="cycling-background current"
        style={{ backgroundImage: `url(${backgroundImages[currentImageIndex]})` }}
      ></div>
      <div 
        className="cycling-background next"
        style={{ backgroundImage: `url(${backgroundImages[nextImageIndex]})` }}
      ></div>
      <div 
        className="content-wrapper" 
        style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/main_front/main.png)` }}
      >
        <h1>Welcome to Prof Answers AI!</h1>
        <img 
          src={`${process.env.PUBLIC_URL}/main_front/profanswers-logo.png`} 
          alt="Prof Answers AI Logo" 
          className="logo"
        />

        {user ? (
          <form onSubmit={handleCourseSubmit} className="course-select-form">
            <div className="select-wrapper">
              <select 
                value={selectedCourse} 
                onChange={handleCourseChange}
                className="course-select"
              >
                <option value="">Select Your Course:</option>
                {courses.map((course) => (
                  <option 
                    key={course.id} 
                    value={course.id}
                    disabled={course.underConstruction}
                  >
                    {course.name} {course.underConstruction ? '(Under Construction)' : ''}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="course-submit-btn" disabled={!selectedCourse}>
              Access AI Tool
            </button>
          </form>
        ) : (
          <div className="login-container">
            <button onClick={() => navigate('/login')}>Login to access your courses</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
